<template>
  <validation-observer
    ref="deductLevelSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="deductLevelSaveModal"
      body-class="position-static"
      centered
      no-close-on-backdrop
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Tên mức xử lý vi phạm thi"
              label-for="name"
            >
              <template v-slot:label>
                Tên mức xử lý vi phạm thi <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên mức xử lý vi phạm thi"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetData.name"
                  type="text"
                  name="name"
                  placeholder="Nhập tên mức xử lý vi phạm thi"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Giá trị trừ"
              label-for="value"
            >
              <template v-slot:label>
                Giá trị trừ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Giá trị trừ"
                rules="required"
              >
                <b-form-input
                  id="value"
                  v-model="targetData.value"
                  type="text"
                  name="value"
                  placeholder="Nhập giá trị trừ"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="8">
            <b-form-group label-for="type">
              <template v-slot:label>
                Loại giá trị trừ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại giá trị trừ"
                rules="required"
              >
                <v-select
                  id="type"
                  v-model="targetData.type"
                  :options="types"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('deductLevelSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'DeductLevelSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    mainData: {
      type: Object,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetData: {
        id: 0,
        name: '',
        organizationId: getUser().orgId,
        value: 0,
        status: 1,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'deductLevel/statuses',
      types: 'deductLevel/types',
      resourceName: 'deductLevel/resourceName',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'deductLevel/createData',
      updateData: 'deductLevel/updateData',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },

    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = { ...this.mainData }
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .deductLevelSaveFormRef
        .reset()
      this.targetData = {
        id: 0,
        name: '',
        organizationId: this.user.orgId,
        value: 0,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .deductLevelSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = (this.isCreated) ? await this.createData(this.targetData) : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('deductLevelSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
